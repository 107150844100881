@import "../styles/variables.scss";
@import "../styles/typography.scss";

.Simple2column {
    position: relative;
    background-color: $blue2;
    padding: 50px 20px;
    @media only screen and (max-width: 850px) {
        padding: 50px 0px;
    }
    .container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        @media only screen and (max-width: 850px) {
            flex-direction: column;
        }
        h3 {
            color: $white;
            font-size: 28px;
            @media only screen and (max-width: 850px) {
                text-align: center;
                margin-bottom: 30px;
            }
        }
        a {
            font-size: 18px;
            display: inline-block;
        }
    }
}