@import "../styles/variables.scss";
@import "../styles/typography.scss";

.subHero {
    background-color: $menuGrey;
    padding: 35px 0;
    @media only screen and (max-width: 850px) {
        overflow: scroll;
    }
    .container {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 850px) {
            gap: 20px;
        }
        .item-element {
            display: flex;
            flex-direction: row-reverse;
            gap: 20px;
            align-items: center;
            @media only screen and (max-width: 850px) {
                width: 48%;
            }
            p {
                max-width: 145px;
                font-size: 14px;
                color: $newBlue;
                font-weight: bold;
                @media only screen and (max-width: 850px) {
                    width: 220px;
                    max-width: 220px;
                }
            }
        }
    }
    img {
        height: 50px;
    }
}