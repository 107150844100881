@import "./reset.scss";
@import "./variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

a {
    color: inherit;
    text-decoration: underline;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

.mainApp {
    position: relative;
}

.container {
    max-width: 1200px;
    padding: 0 15px;
    margin: auto;
}

a.callAction, button.form-submit {
    display: inline-block;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    background-color: $newBlue;
    color: $white;
    box-shadow: 0 8px 9px rgb(($newBlue), 25%);
    border-radius: 10px;
    transition: all 0.4s ease;
    &:hover {
        transform: translateY(-5px);
    }
}

.CookieConsent {
    padding: 15px;
    button {
        background-color: #059ED6!important;
    }
}

.noFound {
    padding: 40px 16px;
    height: 65vh;
    text-align: center;
    h1 {
        font-size: 40px;
        margin-bottom: 30px;
    }
}

