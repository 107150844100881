@import "../styles/variables.scss";
@import "../styles/typography.scss";

.SingleArticle {
    padding: 30px 0;
    .container {
        display: flex;
        flex-direction: column;
        .date-toShow {
            text-align: right;
            margin-bottom: 20px;
        }
        h1 {
            font-size: 25px;
            margin-bottom: 20px;
        }
        img {
            border-radius: 20px;
            max-height: 450px;
            object-fit: cover;
            margin-bottom: 50px;
        }
        .article_body {
            width: 80%;
            margin: auto;
            line-height: 28px;
            padding-bottom: 20px;
            h2 {
                font-size: 22px;
                margin-bottom: 15px;
            }
        }
    }
}