@import "../styles/variables.scss";
@import "../styles/typography.scss";

.header-navigation {
    background-color: $white;
    margin: auto;
    box-shadow: 0 8px 9px rgb(($newBlue), 25%);
    position: relative;
    z-index: 2;
    &.fixed {
        position: sticky;
        top: 0;
        @media only screen and (max-width: 850px) {
            position: relative;
        }
        .topBanner {
            height: 0;
            overflow: hidden;
            padding: 0;
            @media only screen and (max-width: 850px) {
                height: auto;
                overflow: hidden;
                padding: 16px;
            }
        }
    }
    .topBanner {
        padding: 16px;
        background-color: $newBlue;
        text-align: center;
        height: auto;
        transition: all 0.3s ease;
        a {
            text-decoration: none;
            color: $white;
            font-size: 15px;
        }
    }
    .menubigContainer {
        padding: 20px 0;
    }
    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            width: 100px;
            object-fit: cover;
        }
        .menu-wrapper {
            display: flex;
            gap: 20px;
            align-items: center;
            @media only screen and (max-width: 850px) {
                display: none;
                align-items: flex-start;
                padding: 0 20px;
            }
            > li {
                padding: 5px;
                @media only screen and (max-width: 850px) {
                    width: 100%;
                }
                &.hasDropdown {
                    position: relative;
                    &::after {
                        content: "";
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        margin-left: 10px;
                        background: url("../images/down.svg");
                        background-size: cover;
                        @media only screen and (max-width: 850px) {
                            position: absolute;
                            right: 0;
                            top: 10px;
                        }
                    }
                }
                > a {
                    font-size: 18px;
                    text-decoration: none;
                    cursor: pointer;
                }
                ul {
                   //display: none;
                    opacity: 0;
                    overflow: hidden;
                    z-index: -1;
                    visibility: hidden;
                    height: 0;
                   @media only screen and (min-width: 850px) {
                    border-radius: 8px;
                    transform: translateY(-30px);
                    padding-top: 10px;
                    position: absolute;
                    height: auto;
                    width: calc(100% + 38px);
                   }
                   li {
                    
                    padding: 10px;
                    
                    padding-top: 16px;
                    padding-left: 16px;
                    padding-bottom: 16px;
                    padding-right: 32px;
                    @media only screen and (min-width: 850px) {
                        box-shadow: 0px 25px 50px -12px rgb(0 0 0 / 25%);
                        background-color: $menuGrey;
                    }
                    &:first-of-type {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        @media only screen and (min-width: 850px) {
                            padding-top: 20px;
                        }
                    }
                    &:last-of-type {
                        padding-bottom: 20px;
                        @media only screen and (max-width: 850px) {
                            padding-bottom: 0;
                        }
                    }
                   }
                   a {
                    text-decoration: none;
                    font-size: 18px;
                    cursor: pointer;
                    color: $black;
                   }
                   &.active {
                    @media only screen and (max-width: 850px) {
                        opacity: 1;
                        transform: translateY(0);
                        transition: all 0.8s ease;
                        z-index: 2;
                        visibility: visible;
                        height: auto;
                    }
                   }
                }
                &:hover {
                    ul {
                        //display: block;
                        @media only screen and (min-width: 850px) {
                            height: auto;
                            opacity: 1;
                            transform: translateY(0);
                            transition: all 0.8s ease;
                            z-index: 2;
                            visibility: visible;
                        }
                    }
                }
                &.callus {
                    a {
                        background-color: $blue2;
                        padding: 10px 20px;
                    }
                }
            }
            &.active {
                display: flex;
                position: absolute;
                background: $white;
                width: 100%;
                left: 0;
                flex-direction: column;
                top: 181px;
                padding-bottom: 50px;
            }
        }
    }
    .nav-toggle {
        height: 50px;
        width: 32px;
        cursor: pointer;
        position: relative;
        display: none;
        @media only screen and (max-width: 850px) {
            display: block;
        }
        span {
            transition: all 0.5s ease-in-out;
        }
    }
    .nav-toggle span,
        .nav-toggle span:before,
        .nav-toggle span:after {
            cursor: pointer;
            border-radius: 1px;
            height: 4px;
            width: 30px;
            background: $newBlue;
            position: absolute;
            left: 0;
            top: 50%;
            display: block;
            content: '';
        }
        .nav-toggle span:before {
            top: -10px;
          }
          
          .nav-toggle span:after {
            top: 10px;
          }
          
          .nav-toggle span,
          .nav-toggle span:before,
          .nav-toggle span:after {
            transition: all 0.5s ease-in-out;
          }
          
          .nav-toggle.active span {
            background-color: transparent;
          }
          
          .nav-toggle.active span:before,
          .nav-toggle.active span:after {
            top: 0;
          }
          
          .nav-toggle.active span:before {
            transform: rotate(135deg);
          }
          
          .nav-toggle.active span:after {
            transform: rotate(-135deg);
          }
}