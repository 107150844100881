@import "../styles/variables.scss";
@import "../styles/typography.scss";

.static2Column {
    position: relative;
    .item-element {
        background-color: $newBlue;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 100px 15px;
            @media only screen and (max-width: 850px) {
                flex-direction: column;
                gap: 60px;
                padding: 50px 15px;
            }
        }
        .left-side {
            width: 45%;
            color: $white;
            @media only screen and (max-width: 850px) {
                width: 100%;
            }
            h2 {
                font-size: 29px;
                line-height: 35px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            p {
                font-size: 18px;
            }
        }
        img {
            width: 45%;
            @media only screen and (max-width: 850px) {
                width: 100%;
            }
        }
        &.true {
            background-color: $menuGrey;
            .container {
                flex-direction: row-reverse;
                @media only screen and (max-width: 850px) {
                    flex-direction: column;
                }
                .left-side {
                    color: $black;
                }
            }
        }
    }
}