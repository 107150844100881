@import "../styles/variables.scss";
@import "../styles/typography.scss";

.heroBanner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(7, 22, 38, 0.7);
    .backgroundBanner {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .container {
        width: 100%;
        padding: 120px 20px;
        .text-area {
            width: 640px;
            @media only screen and (max-width: 850px) {
                width: auto;
            }
            h1 {
                color: $white;
                font-size: 65px;
                margin-bottom: 40px;
                line-height: normal;
                @media only screen and (max-width: 850px) {
                    font-size: 45px;
                    margin-bottom: 30px;
                }
            }
            p {
                color: $white;
                font-size: 30px;
                line-height: normal;
                margin-bottom: 60px;
                @media only screen and (max-width: 850px) {
                    font-size: 28px;
                    margin-bottom: 45px;
                }
            }
            a {
                background-color: $blue2;
            }
        }
    }
    &.smallHero {
        background-color: $newBlue;
        .container {
            .text-area {
                margin: auto;
                text-align: center;
            }
        }
        .boxesHero {
            display: flex;
            justify-content: space-around;
            gap: 50px;
            @media only screen and (max-width: 850px) {
                flex-direction: column;
            }
            div {
                padding: 32px;
                background-color: $menuGrey;
                flex-grow: 1;
                flex-basis: 0;
                text-align: center;
                border-radius: 8px;
                h3 {
                    font-size: 27px;
                    font-weight: 700;
                    color: $newBlue;
                    margin-bottom: 12px;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    }
    &.boxa-hero {
        .container {
            .text-area {
                width: 100%;
                text-align: center;
            }
        }
    }
}