@import "../styles/variables.scss";
@import "../styles/typography.scss";

.multipleComponent {
    position: relative;
    background-color: $gray;
    padding: 100px 20px;
    @media only screen and (max-width: 850px) {
        padding: 50px 20px;
    }
    .container {
        text-align: center;
    }
    .body-header {
        max-width: 700px;
        margin: auto;
        h2 {
            text-align: center;
            margin-bottom: 10px;
            font-size: 40px;
            color: $newBlue;
            @media only screen and (max-width: 850px) {
                font-size: 36px;
            }
        }
        p {
            color: $grey2;
            margin-bottom: 50px;
        }
    }  
    .items-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 70px;
        @media only screen and (max-width: 850px) {
            justify-content: center;
        }
        .item-element {
            display: flex;
            flex-direction: column;
            width: 232px;
            align-items: center;
            gap: 20px;
            img {
                width: 50px;
            }
            .title-area {
                color: $newBlue;
                font-size: 20px;
                font-weight: 600;
            }
            .body-area {
                color: $grey2;
                strong {
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
    a {
        margin-top: 50px;
    }
    &.storage {
        background-color: $white;
        .items-container {
            .item-element {
                box-shadow: 1px 2px 20px 9px rgb(22 69 115 / 25%);
                padding: 30px 15px;
                border-radius: 10px;
                width: 220px;
                cursor: pointer;
                transition: all 0.4s ease;
                @media only screen and (max-width: 850px) {
                    width: 300px;
                }
                &:hover {
                    transform: scale(1.05);
                }
                img {
                    width: 120px;
                    margin-bottom: -15px;
                }
                .callAction {
                    margin-top: 20px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}