//colors
$white: #ffffff;
$black: #000000;
$blue: #2000F0;
$gray: #f7f7f7;
$visiniu: #6D002A; // rgb(126, 34, 68)
$bej: #faf3ee;
$newBlue: rgb(22, 69, 115);
$grey2: rgb(76, 84, 91);
$blue2: #059ED6;
$menuGrey: #F1F4F7;
$formLabel: rgb(76, 84, 91);
$borders: #ced4da;
