@import "../styles/variables.scss";
@import "../styles/typography.scss";

.formContainer {
    padding: 100px 0;
    background-color: $menuGrey;
    .formHeaderArea {
        width: 40%;
        margin-bottom: 30px;
        @media only screen and (max-width: 850px) {
            width: 100%;
        }
        h2 {
            font-size: 31px;
            color: $newBlue;
            margin-bottom: 10px;
        }
    }
    .formWrapper {
        .row-form {
            display: flex;
            gap: 20px;
            margin-bottom: 25px;
            @media only screen and (max-width: 850px) {
                flex-direction: column;
            }
            .cell-form {
                flex-basis: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 5px;
                label {
                    color: $formLabel;
                    font-weight: 600;
                    &::after {
                        content: "*";
                        color: red;
                        padding-left: 3px;
                    }
                }
                input, select {
                    background-image: none;
                    border: 1px solid $borders;
                    border-radius: 0.25rem;
                    color: $formLabel;
                    display: block;
                    font-size: 16px;
                    line-height: 1.5;
                    margin-bottom: 0;
                    max-width: 100%;
                    padding: 6px 12px;
                }
                select {
                    height: 37.2px;
                }
            }
            .form-submit {
                background-color: $blue2;
                cursor: pointer;
                border: 0;
                font-size: 16px;
            }
        }
    }
    .message-green {
        font-size: 20px;
        background-color: #d1e7dd;
        color: #0f5132;
        border: 1px solid #badbcc;
        padding: 10px;
        margin-bottom: 10px;
    }
    .message-red {
        font-size: 20px;
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
        padding: 10px;
        margin-bottom: 10px;
    }
}