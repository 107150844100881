@import "../styles/variables.scss";
@import "../styles/typography.scss";

.boxaDetail {
    position: relative;
    background-color: $menuGrey;
    padding: 100px 15px;
    .container {
        display: flex;
        gap: 30px;
        box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 37%);
        overflow: hidden;
        padding: 0;
        @media only screen and (max-width: 850px) {
            flex-direction: column;
        }
        .leftC {
            width: 45%;
            @media only screen and (max-width: 850px) {
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .rightC {
            width: 55%;
            padding: 30px 60px 30px 20px;
            @media only screen and (max-width: 850px) {
                width: 100%;
                padding: 30px 20px;
            }
            .headerAreaC {
                margin-bottom: 20px;
                h3 {
                    font-size: 30px;
                    color: $newBlue;
                    margin-bottom: 8px;
                }
            }
            .itemsDimensions {
                display: flex;
                flex-wrap: wrap;
                row-gap: 25px;
                margin-bottom: 80px;
                .item {
                    width: 50%;
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    h4 {
                        color: $newBlue;
                        font-size: 18px;
                        line-height: 18px;
                    }
                    p {
                        font-size: 14px;
                    }
                    img {
                        width: 50px;
                        @media only screen and (max-width: 850px) {
                            width: 25px;
                        }
                    }
                    &:last-of-type {
                        .itemContent {
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            h4 {
                                line-height: inherit;
                            }
                            p {
                                font-weight: bold;
                                font-size: 16px;
                                color: $newBlue;
                            }
                        }
                    }
                }
            }
        }
    }
    .galleryMyWrapper {
        > div {
            display: flex;
            flex-wrap: wrap;
            cursor: pointer;
            margin-top: 100px;
            padding: 0;
            > div {
                flex: 1 0 25%;
                height: 350px;
                max-width: unset;
                padding: unset;
                @media only screen and (max-width: 850px) {
                    height: 300px;
                    flex: 1 0 33%;
                }
                > div {
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }  
        }
    }
}