@import "../styles/variables.scss";
@import "../styles/typography.scss";

.despreNoi {
    .body-area {
        margin: 90px;
        @media only screen and (max-width: 850px) {
            margin: 40px 0;
        }
        h2 {
            font-size: 28px;
            color: $newBlue;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 15px;
        }
    }
}