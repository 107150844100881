@import "../styles/variables.scss";
@import "../styles/typography.scss";

.BlogPageWrapper {
    .heroBanner .container .text-area {
        width: auto;
    }
    .blog_title {
        padding: 30px 0;
        h1 {
            font-size: 30px;
            color: rgb(22, 69, 115);
            margin-bottom: 15px;
        }
        p {
            font-size: 18px;
            letter-spacing: 0.3px;
        }
    }
    .blog_listing_wrapper {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
    }
    .item-single-article {
        flex: 1 0 calc(33.33% - 30px);
        @media only screen and (max-width: 850px) {
            flex: 1 0 100%;
        }
        .overlay {
            height: 350px;
            border-radius: 8px;
            overflow: hidden;
            display: block;
            position: relative;
            margin-bottom: 15px;
            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(7, 22, 38, 0.5);
                position: absolute;
                top: 0;
                left: 0;
            }
            &:hover {
                img {
                    transform: scale(1.14);
                }
            }
        }
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            transition: filter .3s,transform .3s;
        }
        a {
            text-decoration: none;
            h2 {
                font-size: 19px;
                margin-bottom: 10px;
            }
            &:hover {
                h2 {
                    color: rgb(22, 69, 115);
                }
            }
        }
        .date-toShow {
            color: $formLabel;
            margin-bottom: 10px;
        }
    }
    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 20px 0 50px 0;
        .prev-page {
            font-size: 18px;
            cursor: pointer;
        }
        .pagination-link {
            cursor: pointer;
            &.active {
                font-weight: bold;
            }
        }
    }
}