@import "../styles/variables.scss";
@import "../styles/typography.scss";

.footer-area {
    position: relative;
    background-color: $newBlue;
    padding: 80px 20px 50px 20px;
    @media only screen and (max-width: 850px) {
        padding: 45px 20px 50px 20px;
    }
    .footer-menu {
        display: flex;
        justify-content: center;
        gap: 80px;
        @media only screen and (max-width: 850px) {
            gap: 25px;
            flex-wrap: wrap;
        }
        li {
            a {
                text-decoration: none;
                color: $white;
                font-size: 18px;
            }
        }
    }
    .infoArea {
        display: flex;
        margin-top: 50px;
        @media only screen and (max-width: 850px) {
            flex-wrap: wrap;
            gap: 20px;
            flex-direction: column;
        }
        .area-item {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
        a, span {
            text-decoration: none;
            color: $white;
            font-size: 16px;
            text-align: center;
        }
        p {
            font-size: 18px;
            color: $white;
            font-weight: 600;
            text-align: center;
        }
    }
    .copyRights {
        color: $white;
        text-align: center;
        margin-top: 70px;
    }
}