@import "../styles/variables.scss";
@import "../styles/typography.scss";


.faqWrapper {
    position: relative;
    background-color: $menuGrey;
    padding: 100px 0;
    .faqTitleArea {
        width: 54%;
        margin: auto;
        text-align: center;
        margin-bottom: 50px;
        h2 {
            font-size: 30px;
            color: $newBlue;
            margin-bottom: 10px;
        }
    }
    .faqQuestions {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
        .faqBox {
            flex-basis: calc(33.3333% - 20px);
            @media only screen and (max-width: 850px) {
                flex-basis: calc(50% - 20px);
            }
            h3 {
                color: $newBlue;
                font-size: 16px;
                margin-bottom: 8px;
            }
        }
    }
}