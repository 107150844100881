@import "../styles/variables.scss";
@import "../styles/typography.scss";

.userPage_container {
    height: 80vh;
    padding: 100px 0 50px 0;
    @media only screen and (max-width: 850px) {
        height: auto;
    }
    h1 {
        text-align: center;
        font-size: 25px;
    }
    .userPage_detail_wrapper {
        display: flex;
        gap: 30px;
        @media only screen and (max-width: 850px) {
            flex-direction: column;
            align-items: center;
        }
        .photo {
            background-image: url('../images/user_photo.gif');
            width: 500px;
            height: 300px;
            background-size: cover;
            @media only screen and (max-width: 850px) {
                max-width: 370px;
                width: 100%;
                height: 181px;
            }
        }
        .userPage_infos {
            display: flex;
            flex-direction: column;
            max-width: 600px;
            font-size: 18px;
            h2 {
                font-size: 20px;
                margin-bottom: 15px;
            }
            h3 {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 10px;
            }
            span {
                font-weight: bold;
                &.red {
                    color: red;
                }
            }
            a {
                font-weight: bold;
            }
        }
    }
    .openGate {
        display: inline-block;
        padding: 15px 30px;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        background-color: rgb(22, 69, 115);
        color: #ffffff;
        box-shadow: 0 8px 9px rgba(22, 69, 115, 0.25);
        border-radius: 10px;
        transition: all 0.4s ease;
        margin-bottom: 20px;
        margin-top: 10px;
        cursor: pointer;
    }
}