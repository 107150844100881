@import "../styles/variables.scss";
@import "../styles/typography.scss";

.PageLogin {
    height: 70vh;
    padding: 100px 0 50px 0;
    @media only screen and (max-width: 850px) {
        height: auto;
        margin: 0 20px;
    }
    form {
        max-width: 540px;
        margin: auto;
        padding: 30px;
        border-radius: 20px;
        box-shadow: 0 8px 9px rgba(22, 69, 115, 0.25);
        position: relative;
        h1 {
            text-align: center;
            font-size: 25px;
        }
        .login_row {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;
            &::before {
                content: "";
                background-image: url(../images/user_ico.svg);
                width: 20px;
                height: 20px;
                bottom: 8px;
                left: 5px;
                position: absolute;
            }
            input {
                border: 0;
                border-bottom: 1px solid;
                padding: 10px;
                padding-left: 34px;
                &:focus {
                    outline: none;
                    border: 0;
                    border-bottom: 1px solid;
                }
            }
            /* Change the white to any color */
                input:-webkit-autofill,
                input:-webkit-autofill:hover, 
                input:-webkit-autofill:focus, 
                input:-webkit-autofill:active{
                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                }
            label {
                font-size: 12px;
            }
            &.pass_login {
                &::before {
                    background-image: url(../images/password.svg);
                }
            }
        }
        button {
            background-color: #059ED6;
            cursor: pointer;
            border: 0;
            font-size: 16px;
            margin-top: 20px;
            display: block;
            margin: 20px auto 0;
        }
        .error_login {
            margin-top: 20px;
            span {
                color: red;
                font-weight: bold;
            }
        }
    }
}